import React, { useState, FormEvent, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { ItemRenderer, ItemPredicate } from '@blueprintjs/select';
import { FormGroup, InputGroup, Button, Tabs, Tab, Checkbox, Collapse, HTMLSelect, HTMLTable, ControlGroup, Classes, MenuItem, Alert } from "@blueprintjs/core";

import { IconNames } from "@blueprintjs/icons";

import { AxiosInstance } from 'axios';

import * as utils from "./utils";

import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next';

import { Verify, Environment } from './Types'

interface VerifyViewProps {
    verify: Verify,
    baseUrl?: string,
    token?: string | null,
    onEdit?: () => void,
    onNew?: () => void,
    onDelete?: (verify: Verify) => void,
}

function VerifyView({ verify = {} as Verify, baseUrl = "", token = "", onEdit = () => { }, onNew = () => { }, onDelete = () => {} }: VerifyViewProps) {
    const { t, i18n } = useTranslation();
    const [isOpenDelete, setIsOpenDelete] = useState(false)

    if (!("email" in verify)) return null

    return (
        <div>
            <Alert icon="trash" intent="danger" confirmButtonText={t('button_delete')} isOpen={isOpenDelete} onConfirm={() => {onDelete(verify); setIsOpenDelete(false)}} onCancel={() => setIsOpenDelete(false)} cancelButtonText={t('button_no')} canEscapeKeyCancel={true} canOutsideClickCancel={true}>
                <p>{t('confirm_delete_verification', {verify})}</p>
            </Alert>
            <div className="Row">
                <Button className="Col-1-1" icon="add" onClick={() => onNew()}>{t('new')}</Button>
                <Button className="Col-2-1" icon="edit" onClick={() => onEdit()}>{t('edit')}</Button>
            </div>
            <div className="Spacing-V-16" />
            <div className="Row">
                <FormGroup className="Col-1-3" label={t('email')}>
                    <InputGroup readOnly value={verify.email} />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-3" label={t('name')}>
                    <InputGroup readOnly value={verify.name} />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-1" label={t('password')}>
                    <InputGroup readOnly type="password" value="********" />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-1" label={t('date')}>
                    <InputGroup readOnly value={verify.date} />
                </FormGroup>
                <FormGroup className="Col-2-1" label={t('time')}>
                    <InputGroup readOnly value={verify.time} />
                </FormGroup>
                <FormGroup className="Col-3-1" label={t('code')}>
                    <InputGroup readOnly value={verify.code} />
                </FormGroup>
            </div>
            <div className="Spacing-V-16" />
            <div className="Row">
                <Button className="Col-1-1" icon="trash" onClick={() => setIsOpenDelete(!isOpenDelete)}>{t('delete')}</Button>
            </div>
        </div>
    )
}

interface VerifyEditProps {
    verify?: Verify,
    isNew?: boolean,
    onSave?: (verify: Verify) => void,
    onCancel?: () => void;
}

function VerifyEdit({ verify = {} as Verify, isNew = false, onSave = () => { }, onCancel = () => { } }: VerifyEditProps) {
    const { t, i18n } = useTranslation();
    const { register, handleSubmit, reset, setValue } = useForm<Verify>()

    useEffect(() => {
        // Call reset to set default values if verify is passed
        if ("email" in verify) {
            reset(verify)
        }
    }, [verify, reset])

    useEffect(() => {
        // Suggest don't have ref to use with react-hook-form, so register it manually and call setValue when item is selected
        //register({ name: "acType" })
    }, [register])

    if (!isNew && !("email" in verify)) return null

    const onSubmit = (data: Verify) => {
        //console.log("onsubmit called", data)
        onSave(data)
    }

    function highlightText(text: string, query: string) {
        let lastIndex = 0;
        const words = query
            .split(/\s+/)
            .filter(word => word.length > 0)
            .map(escapeRegExpChars);
        if (words.length === 0) {
            return [text];
        }
        const regexp = new RegExp(words.join("|"), "gi");
        const tokens: React.ReactNode[] = [];
        while (true) {
            const match = regexp.exec(text);
            if (!match) {
                break;
            }
            const length = match[0].length;
            const before = text.slice(lastIndex, regexp.lastIndex - length);
            if (before.length > 0) {
                tokens.push(before);
            }
            lastIndex = regexp.lastIndex;
            tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
        }
        const rest = text.slice(lastIndex);
        if (rest.length > 0) {
            tokens.push(rest);
        }
        return tokens;
    }

    function escapeRegExpChars(text: string) {
        //return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
        return text.replace(/([.*+?^=!:${}()|\\])/g, "\\$1");
    }

    const handleHotkey = (e: React.KeyboardEvent<HTMLFormElement>) => {
        //console.log(`ctrl:${e.ctrlKey} alt:${e.altKey} shift:${e.shiftKey} meta:${e.metaKey} key:${e.key} keyCode:${e.keyCode}`)

        // ctrl + s
        if (e.ctrlKey && e.keyCode === 83) {
            e.preventDefault()
            handleSubmit(onSubmit)()
        }
    }

    return (
        <form onKeyDown={handleHotkey}>
            <div className="Row">
                <Button className="Col-1-1" icon="cross" onClick={() => onCancel()}>{t('cancel')}</Button>
                <Button className="Col-2-1" icon="tick" onClick={handleSubmit(onSubmit)}>{t('save')}</Button>
            </div>
            <div className="Spacing-V-16" />
            <div className="Row">
                <FormGroup className="Col-1-3" label={t('email')} labelFor="inputEmail">
                    <InputGroup readOnly={!isNew} id="inputEmail" name="email" inputRef={register} />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-3" label={t('name')} labelFor="inputName">
                    <InputGroup id="inputName" name="name" inputRef={register} />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-1" label={t('password')} labelFor="inputPassword">
                    <InputGroup id="inputPassword" name="password" type="password" inputRef={register} />
                </FormGroup>
            </div>
            <div className="Row">
                <FormGroup className="Col-1-1" label={t('date')} labelFor="inputDate">
                    <InputGroup id="inputDate" name="date" type="date" inputRef={register} />
                </FormGroup>
                <FormGroup className="Col-2-1" label={t('time')} labelFor="inputTime">
                    <InputGroup id="inputTime" name="time" inputRef={register} />
                </FormGroup>
                <FormGroup className="Col-3-1" label={t('code')} labelFor="inputCode">
                    <InputGroup id="inputCode" name="code" inputRef={register} />
                </FormGroup>
            </div>
        </form>
    )
}

interface VerifyMainProps {
    svc: AxiosInstance
    currentEnv?: Environment,
}

function VerifyMain({ svc, currentEnv = {} as Environment}: VerifyMainProps) {
    let { tabId: defaultTabId = "list" }: { tabId: string } = useParams()
    const { t, i18n } = useTranslation();
    const history = useHistory()

    const [data, setData] = useState([] as Verify[])

    const [searchKey, setSearchKey] = useState("email")
    const [searchValue, setSearchValue] = useState("")

    const [showFilters, setShowFilters] = useState(true)

    const [searchName, setSearchName] = useState("")
    const [searchType, setSearchType] = useState("")
    const [searchCcy, setSearchCcy] = useState("")

    const [searchLimit, setSearchLimit] = useState(50)
    const [searchBackward, setSearchBackward] = useState(false)

    const [selectedTabId, setSelectedTabId] = useState(defaultTabId) //useState("list")
    const [lastTabId, setLastTabId] = useState(defaultTabId) //useState("list")
    const [currentVerify, setCurrentVerify] = useState({} as Verify)

    const [currentMode, setCurrentMode] = useState("show")

    useEffect(() => {
        setSelectedTabId(defaultTabId)
    }, [defaultTabId])

    const changeTab = (tabId: string) => {
        history.push(`/verifications/${tabId}`)
        setSelectedTabId(tabId)
    }

    useEffect(() => {
        (async () => {
            refreshVerifications()
        })()
    }, [currentEnv])

    useEffect(() => {
        if (data.length > 0) {
            let i = 0;
            if ("email" in currentVerify) {
                i = data.findIndex(verify => verify.email === currentVerify.email)
            }
            setCurrentVerify(i > 0 ? data[i] : data[0])
        }
    }, [data])

    const refreshVerifications = async () => {
        if ("envId" in currentEnv) {
            try {
                const res = await svc.post('/verifylist',
                    {
                        by: searchKey,
                        start: searchValue,
                        limit: searchLimit,
                        backward: searchBackward,
                        filters: {
                            name: searchName,
                            acType: searchType,
                            ccyCode: searchCcy
                        }
                    });

                setData(res.data.data)
                if (res.data.data.length === 0) {
                    utils.showError(t('no_record'))
                }
            } catch (error) {
                utils.showError(error)
            }                
        }
    }

    const onSubmit = async (e: FormEvent) => {
        e.preventDefault()
        refreshVerifications()
    }

    const editOnSave = async (verify: Verify) => {
        try {
            const res = await svc.post('/verifyupdate', verify)
            utils.showSuccess(t('saved'))
            for (let i = 0; i < data.length; i++) {
                if (data[i].email === res.data.data.email) {
                    data[i] = { ...data[i], ...res.data.data }
                    setData(data)
                    break
                }
            }
            setCurrentVerify(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
    }

    const newOnSave = async (verify: Verify) => {
        try {
            const res = await svc.post('/verifyadd', verify)
            utils.showSuccess(t('saved'))
            refreshVerifications()
            setCurrentVerify(res.data.data)
            setCurrentMode("show")
        } catch (error) {
            utils.showError(error)
        }
    }

    const viewOnDelete = async (verify: Verify) => {
        try {
            //console.log("deleting customer:", cust)
            const res = await svc.post('/verifydelete', verify)
            utils.showSuccess(t('deleted'))
            setData(data.filter(verify => !(verify.email === res.data.email)))
            setCurrentVerify({} as Verify)
            changeTab("list")
        } catch (error) {
            utils.showError(error)
        }
    }

    // currentMode = show
    let detail = <VerifyView verify={currentVerify} baseUrl={svc.defaults.baseURL} token={localStorage.getItem('token')} onEdit={() => setCurrentMode("edit")} onNew={() => {setLastTabId(selectedTabId); setCurrentMode("new")}} onDelete={viewOnDelete}></VerifyView>
    switch (currentMode) {
        case "edit":
            detail = <VerifyEdit verify={currentVerify} onSave={editOnSave} onCancel={() => setCurrentMode("show")}></VerifyEdit>
            break
        case "new":
            detail = <VerifyEdit isNew onSave={newOnSave} onCancel={() => { setCurrentMode("show"); changeTab(lastTabId) }}></VerifyEdit>
            break
    }

    let list = <>
        <div className="Row">
            <Button className="Col-1-1" icon="add" onClick={() => { setLastTabId(selectedTabId); setCurrentMode("new"); changeTab("detail") }}>{t('new')}</Button>
        </div>
        <div className="Spacing-V-16" />
        <HTMLTable striped interactive condensed>
            <thead>
                <tr>
                    <th className="TCol-Email">{t('email')}</th>
                    <th className="TCol-Name">{t('name')}</th>
                    <th className="TCol-Date">{t('date')}</th>
                    <th className="TCol-Date">{t('time')}</th>
                    <th className="TCol-Date">{t('code')}</th>
                </tr>
            </thead>
            <tbody>
                {data.map((verify, i) =>
                    <tr key={i} onDoubleClick={() => { changeTab("detail") }}>
                        <td className="TCol-Email" onClick={() => { setCurrentVerify(verify) }}>{verify.email}</td>
                        <td className="TCol-Name" onClick={() => { setCurrentVerify(verify) }}>{verify.name}</td>
                        <td className="TCol-Date" onClick={() => { setCurrentVerify(verify) }}>{verify.date}</td>
                        <td className="TCol-Date" onClick={() => { setCurrentVerify(verify) }}>{verify.time}</td>
                        <td className="TCol-Date" onClick={() => { setCurrentVerify(verify) }}>{verify.code}</td>
                    </tr>
                )}
            </tbody>
        </HTMLTable>
    </>

    return (
        <>
            <div style={{fontSize: "21px", fontWeight: 700}}>{t('verifications')}</div>
            <div className="Spacing-V-16"></div>
            <form onSubmit={onSubmit}>
                <div className="Row">
                    <FormGroup className="Col-1-3" label={t('search')} labelFor="inputSearchValue">
                        <ControlGroup fill>
                            <HTMLSelect className={Classes.FIXED} value={searchKey} onChange={utils.handleStringChange(s => setSearchKey(s))}>
                                <option value="email">{t('email')}</option>
                                <option value="_recordNo">Record No.</option>
                            </HTMLSelect>
                            <InputGroup
                                id="inputSearchValue"
                                placeholder={t('search')}
                                value={searchValue}
                                onChange={utils.handleStringChange(s => setSearchValue(s))}
                                leftIcon="search"
                                rightElement={<Button icon={IconNames.ARROW_RIGHT} minimal type="submit"></Button>}
                            />
                        </ControlGroup>
                    </FormGroup>
                    {/*<Checkbox className="Col-4-1 Checkbox-LabeledFormGroup" label="Backward" onChange={utils.handleBooleanChange(v => setSearchBackward(v))} />*/}
                    {/*<Button className="Col-5-1 Button-LabeledFormGroup" icon="filter" onClick={() => { setShowFilters(!showFilters) }} >Filters</Button>*/}
                </div>
                <Collapse isOpen={showFilters}>
                    <div className="Row">
                        <FormGroup className="Col-1-3" label={t('name')} labelFor="searchName">
                            <InputGroup id="searchName" value={searchName} onChange={utils.handleStringChange(s => setSearchName(s))} />
                        </FormGroup>
                        <div className="Col-4-2 Col-H">
                            <FormGroup label="Limit" labelFor="searchLimit" >
                                <InputGroup className="Input-XS" id="searchLimit" value={searchLimit.toString()} onChange={utils.handleNumberChange(n => setSearchLimit(n))} />
                            </FormGroup>
                        </div>
                        {/*
                        <div className="Col-4-2 Col-H">
                            <FormGroup label="Type" labelFor="searchType">
                                <InputGroup className="Input-XS" id="searchType" value={searchType} onChange={utils.handleStringChange(s => setSearchType(s))} />
                            </FormGroup>
                            <FormGroup label="Limit" labelFor="searchLimit" >
                                <InputGroup className="Input-XS" id="searchLimit" value={searchLimit.toString()} onChange={utils.handleNumberChange(n => setSearchLimit(n))} />
                            </FormGroup>
                        </div>
                        */}
                    </div>
                </Collapse>
            </form>
            <Tabs onChange={(newTabId) => changeTab(newTabId as string)} selectedTabId={selectedTabId}>
                <Tab id="list" title={t('list')} panel={list} />
                <Tab id="detail" title={t('detail')} panel={detail} />
            </Tabs>
        </>
    );
}

export default VerifyMain